import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BenefitSection from '../components/organisms/BenefitSection';
import CaseStudySection from '../components/organisms/CaseStudySection';
import CreativeAISection from '../components/organisms/CreativeAISection';
import PlatformFeatures from '../components/organisms/PlatformFeatures';
import ProductPageHero from '../components/organisms/ProductPageHero';

const TestAnalyseAndOptimisePage = ({ data: { page: data } }) => (
  <Layout>
    <Seo post={data} />

    <ProductPageHero {...data.testAndOptimise.testAndOptimiseHero} />

    {data.testAndOptimise.testAndAnalyseBenefits.length > 0 && (
      <BenefitSection
        imageSide="right"
        animationName="spirable-test-creative"
        animationBackground="linear-gradient(140.44deg, #FF89DE -24.94%, rgba(201, 57, 57, 0.58) 103.81%)"
        {...data.testAndOptimise.testAndAnalyseBenefits[0]}
      />
    )}
    <PlatformFeatures {...data.testAndOptimise.leadingBenefitsSection} />
    <CreativeAISection {...data.testAndOptimise.creativeAiSection} />
    {data.testAndOptimise.testAndAnalyseBenefits.length > 1 && (
      <BenefitSection
        imageSide="left"
        {...data.testAndOptimise.testAndAnalyseBenefits[1]}
      />
    )}
    {data.testAndOptimise.testAndAnalyseBenefits.length > 2 && (
      <BenefitSection
        imageSide="right"
        animationName="spirable-test-inflight"
        animationBackground="linear-gradient(134.38deg, rgba(255, 137, 222, 0.5) -58.05%, rgba(201, 57, 57, 0.96) 102.25%)"
        {...data.testAndOptimise.testAndAnalyseBenefits[2]}
      />
    )}
    {data.testAndOptimise.testAndAnalyseBenefits.length > 3 && (
      <BenefitSection
        imageSide="left"
        {...data.testAndOptimise.testAndAnalyseBenefits[3]}
      />
    )}
    <CaseStudySection
      {...data.testAndOptimise.caseStudy}
      backgroundImage="linear-gradient(141.05deg, rgba(201, 57, 57, 0.96) -5.42%, rgba(255, 137, 222, 0.5) 104.72%)"
    />
  </Layout>
);

export const pageQuery = graphql`
  query TestAnalyseAndOptimiseQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      testAndOptimise {
        testAndOptimiseHero {
          title
          body
          image {
            ...Image
          }
        }
        testAndAnalyseBenefits {
          lead
          title
          body
          image {
            ...Image
          }
        }
        leadingBenefitsSection {
          lead
          title
          features {
            icon {
              ...Image
            }
            title
            body
          }
        }
        creativeAiSection {
          lead
          title
          body
          image {
            ...Image
          }
          creativeSection {
            lead
            title
            body
            image {
              ...Image
            }
          }
        }
        caseStudy {
          logo {
            ...Image
          }
          title
          body
          cta {
            label
            to
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default TestAnalyseAndOptimisePage;
