import { Link } from 'gatsby';
import React from 'react';
import Image from '../base/Image';

interface ProductPageHeroProps {
  title: string;
  body: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
}

const ProductPageHero: React.FC<ProductPageHeroProps> = ({
  title,
  body,
  image,
}) => (
  <section className="flex flex-col-reverse items-center lg:flex-row">
    <div className="w-full lg:w-1/2 lg:pr-12">
      <Image data={image} className="w-full"/>
    </div>
    <div className="max-w-full px-6 py-16 pr-6 md:ml-10 lg:ml-20 lg:pr-10 lg:w-148 lg:py-0 lg:px-0">
      <h1 className="u-h1">
        {title}
      </h1>
      <p className="max-w-full mb-8 text-xl leading-8 text-body-copy lg:mb-10 w-120">
        {body}
      </p>
      <Link className="u-btn u-btn--primary" to="/get-started">
        Get started
      </Link>
    </div>
  </section>
);

export default ProductPageHero;
