import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Slider from '../molecules/Slider';

interface PlatformFeaturesProps {
  lead: string;
  title: string;
  body?: string;
  features?: {
    icon: {
      sourceUrl: string;
      altText: string;
    };
    title: string;
    body: string;
  }[];
  categories?: {
    name: string;
    tiles: {
      icon: {
        sourceUrl: string;
        altText: string;
      };
      title: string;
      body: string;
    }[];
  }[];
}

const PlatformFeatures: React.FC<PlatformFeaturesProps> = ({
  lead,
  title,
  body,
  features,
  categories,
}) => {
  const [categoryIndex, setCategoryIndex] = useState(0);

  const categoryFeatures = categories
    ? categories[categoryIndex].tiles
    : features;

  return (
    <section className="py-16 overflow-hidden bg-tertiary md:py-32">
      <div className="mb-12 u-container lg:mb-12">
        <div className="items-end justify-between block text-center lg:flex md:text-left">
          <div>
            <div className="u-s1">{lead}</div>
            <h2 className="max-w-xl mx-auto md:mx-0 u-h2">{title}</h2>
            {body && (
              <p className="max-w-full mx-auto text-lg leading-8 text-body-copy w-160 md:mx-0">
                {body}
              </p>
            )}

            {categories && (
              <div className="flex items-center space-x-8 mt-15">
                {categories.map(({ name }, index) => (
                  <button
                    key={index}
                    className={`font-semibold transition focus:outline-none ${
                      index === categoryIndex
                        ? 'text-primary-red'
                        : 'text-black opacity-30'
                    }`}
                    onClick={() => {
                      setCategoryIndex(index);
                    }}
                  >
                    {name}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="relative overflow-visible u-container">
        <Slider gap={24} id={categoryIndex.toString()}>
          {categoryFeatures.map(({ icon, title, body }, index) => (
            <motion.div
              key={`${categoryIndex} ${index}`}
              className={`select-none flex-grow-0 flex-shrink-0 px-10  bg-white rounded-md shadow-card py-13 ${
                categories ? 'w-72' : 'w-96'
              } md:mb-6 lg:mb-0`}
              style={{ height: 312 }}
            >
              <img
                src={icon.sourceUrl}
                alt={icon.altText}
                className="w-auto h-8 mb-15"
              />
              <h2 className="mb-2.5 text-2xl font-semibold leading-130">
                {title}
              </h2>
              {body && <p className="text-body-copy">{body}</p>}
            </motion.div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default PlatformFeatures;
