import { graphql } from 'gatsby';
import React from 'react';
import CtaLink from '../atoms/CtaLink';

interface CaseStudySectionProps {
  logo: {
    sourceUrl: string;
    altText: string;
  };
  title: string;
  body: string;
  cta: {
    label: string;
    to: string;
  };
  quote: string;
  author: {
    name: string;
    title: string;
    photo: {
      sourceUrl: string;
      altText: string;
    };
  };
  backgroundImage?: string;
}

const CaseStudySection: React.FC<CaseStudySectionProps> = ({
  logo,
  title,
  body,
  cta,
  quote,
  author,
  backgroundImage,
}) => (
  <section
    className="bg-center bg-cover lg:bg-right-top"
    style={{
      backgroundImage:
        backgroundImage || `url(/images/case-study-section-bg.jpg)`,
    }}
  >
    <div
      className="py-16 bg-no-repeat md:py-28"
      style={{
        backgroundImage: 'url(/images/case-study-bg-overlay.svg)',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'top left 70%',
      }}
    >
      <div className="flex flex-col justify-between lg:items-center lg:flex-row u-container">
        <div className="w-full mb-12 text-white md:w-8/12 lg:w-5/12 lg:pr-8 xl:pr-16 lg:mb-0">
          <img
            src={logo?.sourceUrl}
            alt={logo?.altText}
            className="w-auto h-5 mb-8"
          />
          <h2 className="mb-3 text-3xl font-semibold tracking-tight leading-tighter lg:text-4xl md:mb-5">
            {title}
          </h2>
          <p className="mb-6 text-white md:mb-8 u-p1">{body}</p>
          {cta && (
            <CtaLink {...cta} color="text-white hover:text-black cursor-pointer" />
          )}

        </div>
        <div className="w-full lg:w-7/12">
          <div className="p-8 bg-white rounded-lg shadow-2xl md:p-20">
            <img src="/images/quotes.png" alt="" className="w-6 h-auto mb-4" />

            <q className="block mb-8 font-semibold leading-snug lg:text-2xl quotes-none">
              {quote}
            </q>
            <div className="flex items-center">
              <img
                src={author.photo.sourceUrl}
                alt={author.photo.sourceUrl}
                className="mr-5 rounded-full w-14 h-14"
              />
              <div>
                <div className="font-semibold">{author.name}</div>
                <div className="text-xs text-body-copy md:text-base">
                  {author.title}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  fragment CaseStudySection on WpPage_Platform {
    caseStudySection {
      logo {
        ...Image
      }
      title
      body
      cta {
        to
        label
      }
      quote
      author {
        name
        title
        photo {
          ...Image
        }
      }
    }
  }
`;

export default CaseStudySection;
