import { Link } from 'gatsby';
import React from 'react';
import CtaLink from '../atoms/CtaLink';
import Image from '../base/Image';
import FadeIn from '../molecules/FadeInWhenVisible';
import LottieAnimation from '../molecules/LottieAnimation';
import ArrowRight from '../atoms/ArrowRight';
interface BenefitSectionProps {
  lead: string;
  title: string;
  body: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
  imageSide?: 'left' | 'right';
  logo?: {
    title: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
  };
  note?: string;
  cta?: {
    label: string;
    to: string;
  };
  primaryCta?: boolean;
  animationName?: string;
  animationBackground?: string;
}

const BenefitSection: React.FC<BenefitSectionProps> = ({
  lead,
  title,
  body,
  image,
  imageSide = 'left',
  logo,
  note,
  cta,
  primaryCta,
  animationName,
  animationBackground,
}) => (
  <section
    className={`flex flex-col-reverse lg:flex-row items-center my-16 u-container md:my-32 ${
      imageSide === 'left' && 'lg:flex-row-reverse'
    }`}
  >
    <div
      className={`lg:w-1/2 w-full pt-10 lg:pt-0 ${
        imageSide === 'left' ? 'lg:pl-24' : 'lg:pr-24'
      }`}
    >
      <FadeIn>
        <div className="u-s1" dangerouslySetInnerHTML={{ __html: lead }} />
      </FadeIn>
      <FadeIn transition={{ delay: 0.1 }}>
        <h2 className="u-h2" dangerouslySetInnerHTML={{ __html: title }} />
      </FadeIn>
      <FadeIn transition={{ delay: 0.2 }}>
        <div
          className="max-w-md text-lg leading-8 text-body-copy"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </FadeIn>

      {logo?.image?.sourceUrl && (
        <div className="pt-10 mt-10 border-t border-ghost-grey lg:mt-12">
          <FadeIn transition={{ delay: 0.4 }} y="0">
            <h3 className="mb-5 text-sm font-semibold text-primary-red">
              {logo.title}
            </h3>
          </FadeIn>
          <FadeIn transition={{ delay: 0.5 }} y="0">
            <Image data={logo.image} className="w-auto h-5" />
          </FadeIn>
        </div>
      )}
      {note && (
        <FadeIn
          transition={{ delay: 0.6 }}
          y="0"
          className="border-t md:pt-10 border-ghost-grey mt-15"
        >
          <p className="text-sm leading-relaxed text-body-copy">{note}</p>
        </FadeIn>
      )}

      {cta?.to && (
        <FadeIn className="mt-8" transition={{ delay: 0.6 }}>
          <div>
            {primaryCta ? (
              <Link
                to={cta.to}
                className="inline-flex items-center text-sm font-semibold transition text-primary-red hover:text-black focus:text-black"
              >
                <span className="mr-3">{cta.label}</span>
                <ArrowRight className="flex-shrink-0" />
              </Link>
            ) : (
              <CtaLink {...cta} />
            )}
          </div>
        </FadeIn>
      )}
    </div>
    <div className="w-full lg:w-1/2">
      <FadeIn>
        {animationName ? (
          <LottieAnimation
            animationBackground={animationBackground}
            lazyData={animationName}
          />
        ) : (
          <Image data={image} className="w-full h-auto" />
        )}
      </FadeIn>
    </div>
  </section>
);

export default BenefitSection;
