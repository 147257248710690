import React from 'react';

interface CreativeAISectionProps {
  lead: string;
  title: string;
  body: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
  creativeSection: {
    lead: string;
    title: string;
    body: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
  };
}

const CreativeAISection: React.FC<CreativeAISectionProps> = ({
  lead,
  title,
  body,
  image,
  creativeSection,
}) => (
  <section
    className="py-16 bg-cover lg:py-32"
    style={{
      backgroundImage:
        'linear-gradient(141.05deg, rgba(201, 57, 57, 0.96) -5.42%, rgba(255, 137, 222, 0.5) 104.72%)',
    }}
  >
    <div className="flex flex-col items-center max-w-xl mx-auto mb-20 text-center u-container">
      <div className="text-white opacity-50 u-s1">{lead}</div>
      <h2 className="text-white u-h2">{title}</h2>
      <p className="text-lg leading-8 text-tertiary">{body}</p>
    </div>

    <div className="u-container">
      <div className="max-w-3xl mx-auto">
        <img
          src={image?.sourceUrl}
          alt={image?.altText}
          className="w-full h-auto"
        />
      </div>
    </div>

    {/* <div className="flex flex-col items-center space-y-16 lg:flex-row u-container lg:space-y-0 lg:space-x-28">
      <div className="lg:w-1/2">
        <img
          src={creativeSection.image.sourceUrl}
          alt={creativeSection.image.altText}
          className="w-full h-auto lg:transform lg:translate-y-24"
        />
      </div>
      <div className="pb-20 lg:w-1/2 lg:pb-0">
        <div className="mb-5 text-lg font-semibold text-white opacity-50">
          {creativeSection.lead}
        </div>
        <h2 className="mb-5 text-white u-h2">{creativeSection.title}</h2>
        <p className="text-lg leading-8 lg:pr-20 text-tertiary">
          {creativeSection.body}
        </p>
      </div>
    </div> */}
  </section>
);

export default CreativeAISection;
